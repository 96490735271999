import { Injectable, inject } from '@angular/core';
import {
  type HttpInterceptor,
  type HttpRequest,
  type HttpHandler,
  type HttpEvent,
  HttpClient,
} from '@angular/common/http';
import { type Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { LocalStorageService } from '../services/persistance.service';
import { LS_AUTH_DATA_KEY } from '../constants/ls-auth-data-key';
import { ConfigService } from '../services/config.service';
import { type AuthData } from '../models/authData';
@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  private readonly userService = inject(UserService);
  private readonly lsService = inject(LocalStorageService);
  private readonly http = inject(HttpClient);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokenData = this.lsService.getAuthData();
    let request = req.clone({
      url: req.url.replace(/([^:])(\/{2,})/g, '$1/'),
    });

    if (!req.headers.get('Authorization') && tokenData) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${tokenData.token}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          return this.http
            .post(`${ConfigService.settings.apiUrl}/api/auth/refresh`, {
              accessToken: tokenData.token,
              refreshToken: tokenData.refreshToken,
            })
            .pipe(
              mergeMap((data: AuthData) => {
                if (!data?.token) {
                  this.userService.logout();
                  return throwError(data);
                }

                this.lsService.set(LS_AUTH_DATA_KEY, data);

                request = request.clone({
                  setHeaders: {
                    Authorization: `Bearer ${data?.token}`,
                  },
                });

                return next.handle(request).pipe(catchError((error) => throwError(error)));
              }),
              catchError((error) => {
                this.userService.logout();
                return throwError(error);
              }),
            );
        }
        return throwError(err);
      }),
    );
  }
}
