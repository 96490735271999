import { Injectable, inject } from '@angular/core';
import { HttpService } from './http.service';

export interface GlobalSearchResults {
  companies: SearchResult;
  files: SearchResult;
  contacts: SearchResult;
  products: SearchResult;
}

export interface SearchResult {
  data: any[];
  count: number;
  total: 0;
}

@Injectable()
export class GlobalSearchService {
  private readonly http = inject(HttpService);

  public async search(term: string): Promise<GlobalSearchResults> {
    return await this.http.get(`api/globalSearch/preview?query=${encodeURIComponent(term)}&limit=3`);
  }
}
