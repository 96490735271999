import { Injectable, inject } from '@angular/core';
import { HttpService } from './http.service';
import { type AppNotification } from '../models/notification';

@Injectable()
export class NotificationService {
  private readonly http = inject(HttpService);

  public async loadNotifications(
    offset: number,
    count: number,
  ): Promise<{ data: AppNotification[]; count: number; total: number }> {
    return await this.http.get(`api/UserNotifications/search?Offset=${offset}&Limit=${count}`);
  }

  public async dismissNotification(id: number): Promise<any> {
    return await this.http.delete(`api/UserNotifications/${id}`);
  }

  public async dismissAllNotifications(): Promise<any> {
    return await this.http.delete(`api/UserNotifications`);
  }
}
